import { ProductStatus } from "../../services/product-details/product-details.interface";
import { differenceInMonths } from "date-fns";

import config from "../../products-config.json";

type Args = {
    productStatus?: ProductStatus
    product: string
    version: string
}

type ReturnType = "success" | "warning" | "error" | undefined;

export const checkProductsReleases = ({ productStatus, product, version }: Args): ReturnType => {
    if (!productStatus) return;
    return Configuration(productStatus, product, version);
}

const Configuration = (productStatus: ProductStatus, product: string, version: string): ReturnType => {
    if (product === "wordpress") return wordpressConfiguration(productStatus, version);
    return standardConfiguration(productStatus)
}


const wordpressConfiguration = (productStatus: ProductStatus, version: string): ReturnType => {
    let activeMajorVersion = "6";    
    if (productStatus.eol === undefined) return;
    if (productStatus.eol === "active") {
        activeMajorVersion = version.split(".")[0];
        return "success";
    }
    if (productStatus.eol != "active" && version.startsWith(activeMajorVersion)) return "warning";

    if (productStatus.eol != "active" && version.split(".")[0] != activeMajorVersion) {
        // const eolDate = new Date(productStatus.eol);
        // const now = Date.now();
        // const difference = differenceInMonths(now, eolDate);
        // if (difference < 12) return "warning";
        // if (difference > 12) return "error";
        return "error";
    }
    return "error";
}

const standardConfiguration = (productStatus: ProductStatus): ReturnType => {    
    if (productStatus.support === undefined || productStatus.eol === undefined ) return;
    if (new Date(productStatus.support) > new Date() || new Date(productStatus.eol) > new Date() ) {
        if (new Date(productStatus.support) < new Date()) return "warning";
        return "success";
    }
    return "error";
}

export const buildKey = (name: string, cycle: string) => `${name}|${cycle}`;

export const getName = (key?: string) => key?.split("|")[0];

export const getCycle = (key?: string) => key?.split("|")[1];