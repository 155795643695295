import { api } from "../../utils/api";
import { ProjectsResponse, Projects } from "./projects.interface";

const URL = '/categories/:category/stages/:stage/projects'

export const ProjectsService = {
  get : async (category: string, stage: string): Promise<Record<string, Projects[]>> => {  
  const response = await api(URL.replace(":category", category).replace(":stage", stage))
  const data: ProjectsResponse = await response.json();
  
  if (!data || !data.body) {
    console.error('Invalid API response', data);
    throw new Error('Invalid API response');
  }

  const removeWhenAPiReturnsTheCorrectValue = data.body.map(project => {
    if (!project.products) {
      throw new Error('project.products is undefined');
    }

    return {
      ...project,
      products: project.products.map((product) => ({
        ...product,
        cycle: product.cycle! || product.version!
      })),
      lastModified: project.lastModified,
    }
  })
  return { [category]: removeWhenAPiReturnsTheCorrectValue }
}
} as const;