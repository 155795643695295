import React, { ChangeEvent } from "react";
import * as S from "./styles";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  TextField,
  InputAdornment,
  IconButton,
  Button
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { DataGrid, GridRenderCellParams, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import Select from "../../components/select";
import { ProjectsService } from "../../services/projects/projects.service";
import { StagesService } from "../../services/stages/stages.service";
import { useCategories } from "../../context/categories";
import { useStages } from "../../context/stages";
import { Projects } from "../../services/projects/projects.interface";
import { ProductDetailsService } from "../../services/product-details/product-details.service";
import { buildKey, checkProductsReleases, getCycle } from "./utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import { sanitizeProductVersion } from "../../services/product-details/utils";
import RefreshIcon from '@mui/icons-material/Refresh';

const PROJECT_COLUMN = { field: "projects", headerName: "Projects", width: 350, editable: false, renderCell: (params: GridRenderCellParams) => <S.BoxStyled>{params.value}</S.BoxStyled> };

type ColorRow = Record<string, "success" | "warning" | "error" | undefined>

type Column = { field: string, headerName: string, width: number, editable: boolean }

type Row = { [key: string]: string }

const initialState = {
  currentCategory: '',
  currentState: ''
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'SET_CATEGORY':
      return { ...state, currentCategory: action.payload };
    case 'SET_STAGE':
      return { ...state, currentStage: action.payload };
    default:
      throw new Error();
  }
}

const Loading = () => (
  <Box width="100%" display="flex" style={{ position: 'absolute', top: "50%" }}>
    <CircularProgress style={{ margin: "0 auto" }} />
  </Box>
)

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarExport />
  </GridToolbarContainer>
)

const Dashboard: React.FC = () => {
  const {categories, loading, setCurrentCategory, currentCategory } = useCategories();
  const {stages, setStages, currentStage, setCurrentStage } = useStages();
  const [projectsByCategoryAndStage, setProjectsByCategoryAndStage] = React.useState<Record<string, Projects[]>>({});
  const [rows, setRows] = React.useState<Row[]>([]);
  const [filteredRows, setFilteredRows] = React.useState<Row[]>([]);
  const [columns, setColumns] = React.useState<Column[]>([])
  const [tableLoading, setTableLoading] = React.useState(false);
  const [isFiltering, setIsFiltering] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const onTextFilterChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const lowercaseValue = value.toLowerCase();

    if (!value && filteredRows.length) setFilteredRows([]);
    const filteredRowsArray = rows.filter(row => row.projects.toLowerCase().includes(lowercaseValue));  // Convert row.projects to lowercase before comparing
    setFilteredRows(filteredRowsArray);
    setIsFiltering(!!value);
  }
  const handleCategoryChange = async (event: SelectChangeEvent) => {
    const category = event.target.value;
    setCurrentCategory(category);
    let stage = state.currentStage;

    const newStages = await StagesService.get(category);

    // Assuming newStages has a default stage, set it to currentStage.
    if (newStages && newStages.length > 0) {
      setStages(newStages);

      let checkIfProd = newStages.find(item => item.value === "prod");
      let checkIfStageExists = newStages.find(item => item.value === currentStage);

      if (checkIfStageExists) {
        stage = currentStage;
      } else if (checkIfProd == undefined) {
        // Set the first stage as default.
        stage = newStages[0].value;
      } else {
        stage = "prod";
      }
    }

    setCurrentStage(stage);
    dispatch({ type: 'SET_STAGE', payload: stage });
    dispatch({ type: 'SET_CATEGORY', payload: category });
  };

  const handleStageChange = async (event: SelectChangeEvent) => {
    const stage = event.target.value;

    setCurrentStage(stage);
    dispatch({ type: 'SET_STAGE', payload: stage });
  };

  const buildColorRows = React.useCallback(async (rows: Row[]) => {
    const productsDetails = await ProductDetailsService.get();
    return rows.flatMap(row => Object
      .entries(row)
      .filter(([key]) => key !== 'id' && key !== 'projects' && key !== 'lastModified'))
      .reduce<ColorRow>((acc, [product, productVersion]) => {
        const version = getCycle(productVersion)!;
        const sanitizedVersion = (product === "laravel" || product === 'nodejs') ? version.split('.')[0] : sanitizeProductVersion(version);
        const productStatus = productsDetails[product] ? productsDetails[product][sanitizedVersion ?? ""] : undefined;
        return ({
          ...acc,
          [productVersion]: checkProductsReleases({ productStatus, product, version })
        });
      }, {});
  }, [])

  // TODO - Refactor this function so we check stage also
  const buildTableContent = React.useCallback(async (category: string, stage: string) => {
    const key = `${category}-${stage}`;

    setTableLoading(true);

    const projectsFromRequest = await ProjectsService.get(category, stage);
    if (!projectsFromRequest) return;
    const projectsArray = Object.values(projectsFromRequest).flat();
    setProjectsByCategoryAndStage(prev => ({ ...prev, [key]: projectsArray }));

    const columns = projectsFromRequest[category]

    const productColumns = columns
      .flatMap(({ products }) => products)
      .map(({ name }) => name)

    const rows = projectsFromRequest[category]

    const productRows = rows
      .map(({ products, domain, lastModified }) => ({
        ...(products.reduce((acc, { name, cycle }) => ({ ...acc, [name]: buildKey(name, cycle), id: `${cycle}${domain}${name}` }), { id: domain, projects: domain, lastModified })),
      }))

    const getColorByProductVersion = await buildColorRows(productRows);
    const filteredProductColumns = [...new Set(productColumns)]
      .map((name) => ({
        field: name,
        headerName: name.toUpperCase(),
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => params.formattedValue ? (
          <S.BoxStyled>
            <S.ChipStyled label={params.formattedValue} color={getColorByProductVersion[params.value]} />
          </S.BoxStyled>
        ) : (<></>),
        valueFormatter: ({ value }: Record<string, string>) => getCycle(value)
      }))

    const localeStringOptions: Intl.DateTimeFormatOptions  = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit'
    };

    PROJECT_COLUMN.headerName = t('dashboard.header.projects');
    const LAST_MODIFIED_COLUMN = { field: "lastModified", headerName: t('dashboard.header.lastDeployment'), width: 350, editable: false, renderCell: (params: GridRenderCellParams) => <S.BoxStyled>{new Date(params.value).toLocaleString(i18n.language,localeStringOptions)}</S.BoxStyled> };
    setColumns([PROJECT_COLUMN, LAST_MODIFIED_COLUMN].concat(filteredProductColumns));
    setRows(productRows);
    setTableLoading(false);
  }, []);


  const onRowClick = (field: string, row: Record<string, string>) => {
    if (!row[field]) return;

    if (field === "projects") {
      return;
    }
    if (field === "lastModified") {
      return;
    }
    return navigate(`/project/${currentCategory}/${currentStage}/${row["projects"]}`);
  }

  React.useEffect(() => {
    const lowercaseValue = searchText.toLowerCase();

    if (!searchText && filteredRows.length) setFilteredRows([]);
    const filteredRowsArray = rows.filter(row => row.projects.toLowerCase().includes(lowercaseValue));  // Convert row.projects to lowercase before comparing
    setFilteredRows(filteredRowsArray);
    setIsFiltering(!!searchText);
  }, [searchText, rows]);

  React.useEffect(() => {
    let category = currentCategory;
    let stage = currentStage;

    if (currentCategory === "") {
      category = "wordpress";
      dispatch({ type: 'SET_CATEGORY', payload: category });
    }

    if (currentStage === "") {
      stage = "prod";
      dispatch({ type: 'SET_STAGE', payload: stage });
    }

    buildTableContent(category, stage);
  }, [state.currentStage, state.currentCategory])

  return (
    <S.Wrapper>
      {tableLoading && <Loading />}
      <S.Header style={{ marginBottom: "2rem" }} >
        <FormControl style={{ width: "200px" }}>
          <InputLabel id="demo-simple-select-label-categories">{t('dashboard.categories')}</InputLabel>
          <Select
            options={categories}
            label={t('dashboard.categories')}
            handleChange={handleCategoryChange}
            value={currentCategory}
            loading={loading}
          />
        </FormControl>
        <FormControl style={{ width: "200px", marginLeft: "1rem" }}>
          <InputLabel id="demo-simple-select-label-stages">{t('dashboard.stages')}</InputLabel>
          <Select
            options={stages}
            label={t('dashboard.stages')}
            handleChange={handleStageChange}
            value={currentStage}
            loading={loading}
          />
        </FormControl>
        <TextField
          style={{ marginLeft: "2rem", flex: 1 }}
          id="outlined-basic"
          label={t('dashboard.search')}
          variant="outlined"
          value={searchText}
          onChange={(event) => {
            onTextFilterChange({target: {value: event.target.value}} as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
            setSearchText(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear search"
                  onClick={() => {
                    setSearchText("");
                    onTextFilterChange({target: {value: ""}} as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          aria-label="refresh"
          variant="contained"
          style={{ marginLeft: "2rem" }}
          onClick={() => {
            buildTableContent(currentCategory, currentStage);
          }}
        >
          <RefreshIcon />
        </Button>
      </S.Header>
      <S.WrapperTable>
        <DataGrid
          rows={isFiltering ? filteredRows : rows}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: 50 } },
          }}
          pageSizeOptions={[50, 100]}
          onCellClick={({ field, row }) => onRowClick(field, row)}
          slots={{ toolbar: CustomToolbar }}
          pagination
        />
      </S.WrapperTable>
    </S.Wrapper>
  );
};

export default Dashboard;